import React from 'react';
import './Services.css'
import Accounting from '../assets/Accounting.jpg';
import appfolio from '../assets/technologies/appofolio.png';
import yardi from '../assets/technologies/yardi.png';
import quick from '../assets/technologies/quickbooks.png';
import entrata from '../assets/technologies/entrata.png';
import ramp from '../assets/technologies/ramp.png';
import rent from '../assets/technologies/rentmanager.png';
import podio from '../assets/technologies/podio.png';
import img from '../assets/Accounting.jpg'


function Service2() {
  return (
    <div>
<div className="title">     
  <img className='bgimg' src={img} alt="" /><div className="h2wrapper"> <h2>Outsource<br />
Accounting & Finance<br />
Services
</h2></div>
</div>   

<div className="main">
  <div className="mainleft">
    <img src={Accounting} alt="" width="350px"/>
  </div>
  <div className="mainright">
    <p>We have a team of amazing accounting professionals who can step in at a moment’s notice. OGR’s strategic accounting outsource solutions allow our clients to have peace of mind knowing everything in their accounting department is being handled by seasoned professionals.</p>
  </div>
</div>
<div className="titlee">      <h3>Expertise</h3>
<div className="secondmain">
  <div className="secondmainleft">
    <ul>
      <li>Accounts Receivable</li>
      <li>Accounts Receivable

</li>
      <li>Accounts Payable
</li>
      <li>Bank Reconciliations
</li>
      <li>Credit Card reconciliation
</li>
      <li>Fixed Assets
</li>
     


    </ul>
  </div>
  <div className="secondmainright">
    <ul>
    <li>Expense Reporting
</li>
      <li>Payroll Processing
</li>
      <li>Month-end Close
</li>
      <li>Financial Statement Consolidation
      </li>
      <li>Customized Financial Reporting</li>
    </ul>
  </div>
</div>
</div>  
<div className="titlee">      <h3>Technologies</h3></div>
<div className="thirdmain">
  <img src={appfolio} alt="" />
  <img src={yardi} alt="" />
  <img src={quick} alt="" />
  <img src={entrata} alt="" />
  <img src={ramp} alt="" />
  <img src={rent} alt="" />
  <img src={podio} alt="" />



</div>


<div className="btn"></div>
    
    </div>
  )
}

export default Service2;
