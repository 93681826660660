import React from 'react';
import './Industry.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode, faChartBar, faCopy, faMarker, faUserGear, faCoins ,faHouse,faReceipt,faUtensils} from '@fortawesome/free-solid-svg-icons';

export default function Industry() {

    // Icons
    const faLaptopCodeIcon = <FontAwesomeIcon icon={faLaptopCode} />
    const faChartBarIcon = <FontAwesomeIcon icon={faChartBar} />
    const faCopyIcon = <FontAwesomeIcon icon={faCopy} />
    const faMarkerIcon = <FontAwesomeIcon icon={faMarker} />
    const faUserGearIcon = <FontAwesomeIcon icon={faUserGear} />
    const acc = <FontAwesomeIcon icon={faReceipt} />
    const ghar = <FontAwesomeIcon icon={faHouse} />
    const res = <FontAwesomeIcon icon={faUtensils} />

  return (
    <>
     <div id="services">
        <div className="container">
            <div className="title_headling">
                <h3>The Industry we Offer Services for</h3>
                <p>We Deal in Following Industries</p>
            </div>
            <div className="service_wrapper">
                <div className="service_box">
                    <div className="service_icon blue_icon">{acc}</div>
                    <h4 className="number">01</h4>
                    <div className="service_content">
                        <h5>Accounting</h5>
                        <p style={{color:'white'}}>At OGR, we boast top-notch offshore accounting professionals who possess the essential technical and communication skills to meet our clients’ needs. Our teams are proficient in popular accounting platforms</p>
                        <a href="/" className="read" style={{color:'white'}}>Read more</a>
                    </div>
                </div> 
                <div className="service_box">
                    <div className="service_icon">{ghar}</div>
                    <h4 className="number">02</h4>
                    <div className="service_content">
                        <h5>Property Managment</h5>
                        <p style={{color:'white'}}>A property management virtual assistant plays a crucial role by providing invaluable support for streamlining operations in the business. Since this role can accomplish most tasks online, they work remotely and utilize various task management tools</p>
                        <a href="/" className="read" style={{color:'white'}}>Read more</a>
                    </div>
                </div>
                <div className="service_box">
                    <div className="service_icon green_icon">{res}</div>
                    <h4 className="number">03</h4>
                    <div className="service_content">
                        <h5>Restaurant</h5>
                        <p style={{color:'white'}}>Lorem ipsum dolor sit amet sed consectetur adipisicing elit. doloret quas saepe autem, dolor set.</p>
                        <a href="/" className="read" style={{color:'white'}}>Read more</a>
                    </div>
                </div>
                {/* <div className="service_box">
                    <div className="service_icon">{faMarkerIcon}</div>
                    <h4 className="number">04</h4>
                    <div className="service_content">
                        <h5>Hotel Managment</h5>
                        <p>Lorem ipsum dolor sit amet sed consectetur adipisicing elit. doloret quas saepe autem, dolor set.</p>
                        <a href="/" className="read">Read more</a>
                    </div>
                </div>
                <div className="service_box">
                    <div className="service_icon">{faMarkerIcon}</div>
                    <h4 className="number">04</h4>
                    <div className="service_content">
                        <h5>Construction</h5>
                        <p>Lorem ipsum dolor sit amet sed consectetur adipisicing elit. doloret quas saepe autem, dolor set.</p>
                        <a href="/" className="read">Read more</a>
                    </div>
                </div> */}
                {/* <div className="service_box">
                    <div className="service_icon green_icon">{faUserGearIcon}</div>
                    <h4 className="number">05</h4>
                    <div className="service_content">
                        <h5>Customer Oriented</h5>
                        <p>Lorem ipsum dolor sit amet sed consectetur adipisicing elit. doloret quas saepe autem, dolor set.</p>
                        <a href="/" className="read">Read more</a>
                    </div>
                </div>
                <div className="service_box">
                    <div className="service_icon blue_icon">{faCoinsIcon}</div>
                    <h4 className="number">06</h4>
                    <div className="service_content">
                        <h5>Solution Focused</h5>
                        <p>Lorem ipsum dolor sit amet sed consectetur adipisicing elit. doloret quas saepe autem, dolor set.</p>
                        <a href="/" className="read">Read more</a>
                    </div>
                </div> */}
            </div>
        </div>
     </div>
    </>
  )
}
