import React from 'react';
import Accounting from '/Users/Arham/Downloads/react-project-business-app-main/react-project-business-app-main/src/assets/Accounting.jpg';
import appfolio from '/Users/Arham/Downloads/react-project-business-app-main/react-project-business-app-main/src/assets/technologies/appofolio.png';
import yardi from '/Users/Arham/Downloads/react-project-business-app-main/react-project-business-app-main/src/assets/technologies/yardi.png';
import quick from '/Users/Arham/Downloads/react-project-business-app-main/react-project-business-app-main/src/assets/technologies/quickbooks.png';
import entrata from '/Users/Arham/Downloads/react-project-business-app-main/react-project-business-app-main/src/assets/technologies/entrata.png';
import ramp from '/Users/Arham/Downloads/react-project-business-app-main/react-project-business-app-main/src/assets/technologies/ramp.png';
import rent from '/Users/Arham/Downloads/react-project-business-app-main/react-project-business-app-main/src/assets/technologies/rentmanager.png';
import podio from '/Users/Arham/Downloads/react-project-business-app-main/react-project-business-app-main/src/assets/technologies/podio.png';

function Industry3() {
  return <div>
  <div className="title">     
    <img className='bgimg' src={Accounting} alt="" /><div className="h2wrapper"> <h2>Outsource<br />
    Restaurant <br />
  Services
  </h2></div>
  </div>     
  
  <div className="main">
    <div className="mainleft">
      <img src={Accounting} alt="" width="350px"/>
    </div>
    <div className="mainright">
      <p>Unlock your restaurant’s full potential through outsourcing restaurant services. Raise your coffee cup and toast to streamlined finances, growth, and a future where your culinary dreams find perfect balance. </p>
    </div>
  </div>
  <div className="titlee">      <h3>Expertise</h3>
  <div className="secondmain">
    <div className="secondmainleft">
      <ul>
        <li>Why Outsourced Restaurant Services? Because It Enhances Your Restaurant’s Efficiency</li>
        <li>As your restaurant brand blossoms, so do the challenges. Staff overload within your accounting team becomes a familiar tune. Imagine dozens of vendors scattered across multiple locations, online ordering systems doing their own dance, and POS systems that stubbornly refuse to integrate seamlessly. Add in the time-consuming reconciliations, and suddenly, your accounting feels like a jigsaw puzzle missing a few crucial pieces
  
  </li>
        <li>Streamlined Vendor Management: Handling dozens of vendors? Consider it managed. Our expert accountants effortlessly process invoices, payments, and vendor relationships, allowing you to focus on perfecting your secret sauce.
  </li>
        <li>Online Ordering: Our expertise transforms chaotic online ordering systems into a smooth operation. We ensure orders seamlessly integrate into your books, like a perfectly plated dish reaching a hungry diner.
  </li>
        <li>POS Integration: POS systems giving you trouble? We bring them into perfect harmony, syncing sales data, inventory, and financial records. Your balance sheet will thank us.
  </li>
        <li>Reconciliations: Tedious reconciliations? We make them lightning-fast routines. Your financial statements will be as crisp as freshly baked croissants.
          </li>

       
  
  
      </ul>
    </div>
    
  </div>
  </div>  
  <div className="titlee">      <h3>Technologies</h3></div>
  <div className="thirdmain">
    <img src={appfolio} alt="" />
    <img src={yardi} alt="" />
    <img src={quick} alt="" />
    <img src={entrata} alt="" />
    <img src={ramp} alt="" />
    <img src={rent} alt="" />
    <img src={podio} alt="" />
  
  
  
  </div>
  
  
  <div className="btn"></div>
      
      </div>;
}

export default Industry3;
