import React from 'react';
import './Blog.css';
import BlogImage from '../../assets/blog/blog.jpg';
import { Link } from 'react-router-dom';

export default function Blog() {
  return (
    <>
    <section id="blog">
        <div className="container">
            <div className="blog_wrapper">
                <div className="blog_col">
                    <h3>WE HELP BUSINESSES LAUNCH, GROW AND SUCCEED</h3>
                    <p>We’re solely focused on exceeding expectations of what a BPO and offshore partner can be. Our consultants take the time to understand your business and your goals, and our unique outsourcing model ensures that our clients receive prompt and accurate work from a team they can always rely on.</p>
                    <div className="btn_wrapper">
                        <a className="btn" ><Link to="/contactp" className="dropdown-item">Get Started</Link></a>
                    </div>
                </div>
                <div className="blog_col">
                    <div className="blog_image">
                        <img src={BlogImage} alt="Blog" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
