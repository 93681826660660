import React,{useState, useEffect} from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom


export default function Footer() {

    // Icons
    const faArrowUpIcon = <FontAwesomeIcon icon={faArrowUp} />

    // State
    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top:0,
            behavior:"smooth"
        })
    }

    const listenToScroll = () => {
        let heightToHidden = 250;
        const windowScroll = document.body.scrollTop || document.documentElement.scrollTop;

        (windowScroll > heightToHidden) ? setIsVisible(true) : setIsVisible(false);
    }

    useEffect(()=>{
        window.addEventListener("scroll", listenToScroll);
    })

  return (
    <>
    
    <footer>
      <div className="content">
        <div className="top">
          <div className="logo-details">
            <div className="navbar_brand">

            <Link to="/">
                            <div className='wrapperdiv'>
                                <h3 className='h3oflogo' style={{color:'white'}}>OPTIMIZE</h3>
                                <span className='pof' style={{color:'white',fontSize:'15px'}}> Global Resuorces</span>
                                </div>
                            </Link>
                            </div>
          </div>
          <div className="media-icons">
            <a href="#"><i className="fab fa-facebook-f"></i></a>
            <a href="#"><i className="fab fa-twitter"></i></a>
            <a href="#"><i className="fab fa-instagram"></i></a>
            <a href="#"><i className="fab fa-linkedin-in"></i></a>
            <a href="#"><i className="fab fa-youtube"></i></a>
          </div>
        </div>
        <div className="link-boxes">
          {/* <ul className="box">
            <li className="link_name">Industries</li>
            <li><Link to="/industry1" className="dropdown-item">Accounting & Finance</Link></li>
            <li><Link to="/industry2" className="dropdown-item">Property management</Link></li>
            <li><Link to="/industry3" className="dropdown-item">Restaurant</Link></li>
           
          </ul> */}
          <ul className="box">
            <li className="link_name">Services</li>
            <li><Link to="/service1" className="dropdown-item">Accounting & Finance</Link></li>
            <li><Link to="/service3" className="dropdown-item">Administrative Support</Link></li>
            <li><Link to="/service4" className="dropdown-item">Customer Services</Link></li>
            
          </ul>
         
          <ul className="box input-box">
            <li className="link_name">Subscribe</li>
            <li><input type="text" placeholder="Enter your email" /></li>
            <li><input type="button" value="Subscribe" /></li>
          </ul>
        </div>
      </div>
      <div className="bottom-details">
        <div className="bottom_text">
          <span className="copyright_text">
            Copyright © 2021 <a href="#">OPTIMIZE GLOBAL RESOURCES</a> All rights reserved
          </span>
          <span className="policy_terms">
            <a href="#">Privacy policy</a>
<p>           <a href="https://www.linkedin.com/in/arham-nadeem-58b71a231/"  target='_blank'>Made by Arham </a>
<a href="https://wa.me/+923407287965" target='_blank'>Click This to Enquire About Development Services</a>
</p>    
     </span>
        </div>
        
      </div>
      
    </footer>
  


    {/* Scroll To Top */}
    {
        isVisible && (
            <div className="scroll_top" onClick={scrollToTop}>
                {faArrowUpIcon}
            </div>
        )
    } 
    </>
  )
}
