import React from 'react';
import './Services.css'
import Accounting from '../assets/Accounting.jpg';
import appfolio from '../assets/technologies/appofolio.png';
import yardi from '../assets/technologies/yardi.png';
import quick from '../assets/technologies/quickbooks.png';
import entrata from '../assets/technologies/entrata.png';
import ramp from '../assets/technologies/ramp.png';
import rent from '../assets/technologies/rentmanager.png';
import podio from '../assets/technologies/podio.png';
import img from '../assets/Accounting.jpg'


function Service4() {
  return (
    <div>
<div className="title">     
  <img className='bgimg' src={img} alt="" /><div className="h2wrapper"> <h2>Outsource<br />
Accounting & Finance<br />
Services
</h2></div>
</div> 

<div className="main">
  <div className="mainleft">
    <img src={Accounting} alt="" width="350px"/>
  </div>
  <div className="mainright">
    <p>Our agents can help you improve your day-to-day activities effectively by employing professionals who have customer service mindset to adapt themselves to multi-taking and resolve issues quickly.</p>
  </div>
</div>
<div className="titlee">      <h3>Expertise</h3>
<div className="secondmain">
  <div className="secondmainleft">
    <ul>
      <li>Call Handling</li>
      <li>Complaints Handling

</li>
      <li>Problem Resolution
</li>
    
     


    </ul>
  </div>
  <div className="secondmainright">
    <ul>
    <li>Customer Assistance
</li>
      <li>Troubleshooting
</li>
 
    </ul>
  </div>
</div>
</div>  
<div className="titlee">      <h3>Technologies</h3></div>
<div className="thirdmain">
  <img src={appfolio} alt="" />
  <img src={yardi} alt="" />
  <img src={quick} alt="" />
  <img src={entrata} alt="" />
  <img src={ramp} alt="" />
  <img src={rent} alt="" />
  <img src={podio} alt="" />



</div>


<div className="btn"></div>
    
    </div>
  )
}

export default Service4;
