import React from 'react';
import './Services.css'
import Accounting from '../assets/Accounting.jpg';
import appfolio from '../assets/technologies/appofolio.png';
import yardi from '../assets/technologies/yardi.png';
import quick from '../assets/technologies/quickbooks.png';
import entrata from '../assets/technologies/entrata.png';
import ramp from '../assets/technologies/ramp.png';
import rent from '../assets/technologies/rentmanager.png';
import podio from '../assets/technologies/podio.png';
import img from '../assets/Accounting.jpg'


function Service3() {
  return (
    <div>
<div className="title">     
  <img className='bgimg' src={img} alt="" /><div className="h2wrapper"> <h2>Outsource<br />
Accounting & Finance<br />
Services
</h2></div>
</div>   

<div className="main">
  <div className="mainleft">
    <img src={Accounting} alt="" width="350px"/>
  </div>
  <div className="mainright">
    <p>Administrative support agent can help with a company’s everyday operations. Our agents are bright, talented, and able to multi-task. They can be relied upon to take over the admin functions of your business so that you can devote your time to what you do best</p>
  </div>
</div>
<div className="titlee">      <h3>Expertise</h3>
<div className="secondmain">
  <div className="secondmainleft">
    <ul>
      <li>Data Entry</li>
      <li>Research
</li>
      <li>Calendar Scheduling
</li>
 
     


    </ul>
  </div>
  <div className="secondmainright">
    <ul>
    <li>Email Management
</li>
      <li>HR Process Support
</li>
      
    </ul>
  </div>
</div>
</div>  
<div className="titlee">      <h3>Technologies</h3></div>
<div className="thirdmain">
  <img src={appfolio} alt="" />
  <img src={yardi} alt="" />
  <img src={quick} alt="" />
  <img src={entrata} alt="" />
  <img src={ramp} alt="" />
  <img src={rent} alt="" />
  <img src={podio} alt="" />



</div>


<div className="btn"></div>
    
    </div>
  )
}

export default Service3;
