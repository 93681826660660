import React, { useRef } from 'react';
import './Contact.css';
import ContactImage from '../../assets/contact/contact.jpg';
import emailjs from '@emailjs/browser';

export default function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_8529j6m', 'template_3syf7rz', form.current, 'QUP09LX8HP2DhRigX')
      .then(
        () => {
          console.log('SUCCESS!');
          alert('Message sent successfully!');
        },
        (error) => {
          console.log('FAILED...', error.text);
          alert('Failed to send message, please try again.');
        }
      );
  };

  return (
    <section id="contact">
      <div className="container">
        <div className="contact_wrapper">
          <div className="contact_col">
            <div className="contact_image">
              <img src={ContactImage} alt="Contact" />
            </div>
          </div>
          <div className="contact_col">
            <h2>Contact Us</h2>
            <form ref={form} onSubmit={sendEmail}>
              <div className="input_wrapper">
                <input type="text" name="from_name" className="form-control" placeholder="Your Name..." autoComplete="off" required />
              </div>
              <div className="input_wrapper">
                <input type="text" name="from_phone" className="form-control" placeholder="Your Phone..." autoComplete="off" required />
              </div>
              <div className="input_wrapper">
                <input type="email" name="from_email" className="form-control" placeholder="Your Email..." autoComplete="off" required />
              </div>
              <div className="input_wrapper">
                <textarea name="message" placeholder="Write a Message..." required></textarea>
              </div>
              <div className="btn_wrapper">
                <button type="submit" className="btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
