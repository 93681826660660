import React, { useState, useEffect } from 'react';
import './Navbar.css';
import Logo from '../../assets/logo/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

export default function Navbar() {
    const [sidenav, setSidenav] = useState(false);
    const [sticky, setSticky] = useState(false);
    const [servicesDropdown, setServicesDropdown] = useState(false);
    const [industriesDropdown, setIndustriesDropdown] = useState(false);

    const menuIcon = <FontAwesomeIcon icon={faBars} />

    const sidenavShow = () => {
        setSidenav(!sidenav);
    }

    const toggleServicesDropdown = () => {
        setServicesDropdown(!servicesDropdown);
        setIndustriesDropdown(false);
    }

    const toggleIndustriesDropdown = () => {
        setIndustriesDropdown(!industriesDropdown);
        setServicesDropdown(false);
    }

    useEffect(() => {
        const handleScroll = () => {
            setSticky(window.scrollY > 20);
        }

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <header id="site_header" className={`${sticky ? "sticky" : ''}`}>
                <div className="container">
                    <nav className="navbar" id="Navbar">
                        <div className="navbar_brand">
                            <Link to="/">
                            <div className='wrapperdiv'>
                                <h3 className='h3oflogo'>OPTIMIZE</h3>
                                <span className='pof'> Global Resuorces</span>
                                </div>
                            </Link>
                        </div>
                        <div className="navbar_toggler" onClick={sidenavShow}>
                            {menuIcon}
                        </div>
                        <div className={`menu_items ${sidenav ? 'active' : ''}`}>
                            <ul>
                                <li>
                                    <Link to="/" className="nav-link">Home</Link>
                                </li>
                                <li>
                                    <Link to="/about" className="nav-link">About Us</Link>
                                </li>
                                <li className="dropdown" onMouseEnter={toggleServicesDropdown} onMouseLeave={() => setServicesDropdown(false)}>
                                    <Link to="/service" className="nav-link dropdown-toggle">Services</Link>
                                    {servicesDropdown && (
                                        <ul className="dropdown-menu">
                                            {/* <li><Link to="/service" className="dropdown-item">All Services</Link></li> */}
                                            <li><Link to="/service1" className="dropdown-item">Accounting & Finance</Link></li>
                                            {/* <li><Link to="/service2" className="dropdown-item">Finance</Link></li> */}
                                            <li><Link to="/service3" className="dropdown-item">Administrative Support</Link></li>
                                            <li><Link to="/service4" className="dropdown-item">Customer Services</Link></li>
                                        </ul>
                                    )}
                                </li>
                                {/* <li className="dropdown" onMouseEnter={toggleIndustriesDropdown} onMouseLeave={() => setIndustriesDropdown(false)}>
                                    <Link to="#" className="nav-link dropdown-toggle">Industries</Link>
                                    {industriesDropdown && (
                                        <ul className="dropdown-menu">
                                           
                                            <li><Link to="/industry1" className="dropdown-item">Accouning</Link></li>
                                            <li><Link to="/industry2" className="dropdown-item">Property  Management</Link></li>
                                            <li><Link to="/industry3" className="dropdown-item">Restaurant</Link></li>
                                         
                                        </ul>
                                    )}
                                </li> */}
                                <li>
                                    <Link to="/contactp" className="nav-link">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        </>
    );
}
