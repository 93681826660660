import React from 'react';
import Accounting from '/Users/Arham/Downloads/react-project-business-app-main/react-project-business-app-main/src/assets/Accounting.jpg';
import appfolio from '/Users/Arham/Downloads/react-project-business-app-main/react-project-business-app-main/src/assets/technologies/appofolio.png';
import yardi from '/Users/Arham/Downloads/react-project-business-app-main/react-project-business-app-main/src/assets/technologies/yardi.png';
import quick from '/Users/Arham/Downloads/react-project-business-app-main/react-project-business-app-main/src/assets/technologies/quickbooks.png';
import entrata from '/Users/Arham/Downloads/react-project-business-app-main/react-project-business-app-main/src/assets/technologies/entrata.png';
import ramp from '/Users/Arham/Downloads/react-project-business-app-main/react-project-business-app-main/src/assets/technologies/ramp.png';
import rent from '/Users/Arham/Downloads/react-project-business-app-main/react-project-business-app-main/src/assets/technologies/rentmanager.png';
import podio from '/Users/Arham/Downloads/react-project-business-app-main/react-project-business-app-main/src/assets/technologies/podio.png';



function Industry2() {
  return (
    <div>
<div className="title">     
  <img className='bgimg' src={Accounting} alt="" /><div className="h2wrapper"> <h2>Outsource<br />
Peoperty Management<br />
Services
</h2></div>
</div>     

<div className="main">
  <div className="mainleft">
    <img src={Accounting} alt="" width="350px"/>
  </div>
  <div className="mainright">
    <p>A property management virtual assistant plays a crucial role by providing invaluable support for streamlining operations in the business. Since this role can accomplish most tasks online, they work remotely and utilize various task management tools.</p>
  </div>
</div>
<div className="titlee">      <h3>Expertise</h3>
<div className="secondmain">
  <div className="secondmainleft">
    <ul>
      <li>A PMVA handles various stages of the property management process. Starting with onboarding new landlords and advertising properties to attract prospective tenants.</li>
      <li>Accounts Receivable

</li>
      <li>A PMVA handles Rental Application Processing including Income Verifications, Reference Checks, & Screening Credit Score
</li>
      <li>They also manage tenant prospecting, lease agreements, property inspections, and repair coordination. At the same time, PMVA handles lease renewals, vacates, and relisting tasks, ensuring smooth operations throughout the property management lifecycle.
</li>
      <li>The PMVA is fully trained to handle HOA Services including Setting up Recurring Payments, Paying Outstanding Dues, Resolving Violations, & Maintaining Records.
</li>
      <li>The PMVA is also fully trained to handle Utility Services including Connections/Disconnections, Payment Processing, Tenant Chargeback, & Creating Online Portals.
</li>
<li>
While the PM’s flow is straightforward, it is time-consuming and involves ticking off a lot of boxes. Delegating tasks to a virtual assistant allows property managers to focus on growth and tenant satisfaction.
</li>
     


    </ul>
  </div>
  
</div>
</div>  
<div className="titlee">      <h3>Technologies</h3></div>
<div className="thirdmain">
  <img src={appfolio} alt="" />
  <img src={yardi} alt="" />
  <img src={quick} alt="" />
  <img src={entrata} alt="" />
  <img src={ramp} alt="" />
  <img src={rent} alt="" />
  <img src={podio} alt="" />



</div>


<div className="btn"></div>
    
    </div>
  )
}

export default Industry2;
