import React from 'react';
import './Home.css';
import BannerImage from '../../assets/banner/banner-img.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faShieldHalved, faChartArea } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

export default function Home() {

    // Icons
    const faChartIcon = <FontAwesomeIcon icon={faChartLine} />
    const faShieldIcon = <FontAwesomeIcon icon={faShieldHalved} />
    const faChartAreaIcon = <FontAwesomeIcon icon={faChartArea} />

  return (
    <>
    <section id="home">
        {/* <div className="banner_image"></div> */}
        <div className="container">
            <div className="banner_outer">
                <div className="col">
                    <h3 className="titl">
                    ACHIEVING Your <span> Business Goals</span>
                    </h3>
                    <p>Combining expert knowledge, optimized practices, and advanced technology to provide unmatched BPO experiences for our clients</p>
                    <div className="btn_wrapper">
                        <a className="btn" href="/"><Link to="/contactp" className="dropdown-item">Book a Free Consultation</Link></a>
                    </div>
                </div>
                <div className="col">
                    <div className="sub_banner_image">
                        <img src={BannerImage} alt="Banner_image" />
                    </div>
                    {/* <div className="banner_style_1">
                        {faChartIcon}
                        <h4>Bubsiness Analysis</h4>
                    </div> */}
                    {/* <div className="banner_style_1 banner_style_2">
                        {faShieldIcon}
                        <h4>99.9% Success</h4>
                    </div> */}
                    {/* <div className="banner_style_1 banner_style_3">
                        {faChartAreaIcon}
                        <h4>Strategy</h4>
                    </div> */}
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
