import "./Ghar.css";
import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Services from "./components/Services/Services";
import Blog from "./components/Blog/Blog";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import React from 'react';
import { Route, Routes } from 'react-router-dom';
// import Contactp from './components/Contact/Contactp';






// Import Route, Routes, and Link



function Ghar() {
  return (
    <div className="App">
      {/* Components */}
      {/* <Navbar /> */}
      <Home />
      <About />
      <Services />
      <Blog />
      <Contact />
      {/* <Footer /> */}

     
      {/* <Contactp /> */}
    </div>
  );
}

export default Ghar;
