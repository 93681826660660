import React from 'react';
import "./App.css";
import { Route, Routes } from 'react-router-dom';
import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Services from "./components/Services/Services";
import Blog from "./components/Blog/Blog";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Contactp from './components/Contact/Contactp';
import Ghar from './Ghar.jsx';

import Service1 from './services/Service1.jsx';
import Service2 from './services/Service2.jsx';
import Service3 from './services/Service3.jsx';
import Service4 from './services/Service4.jsx';
import Industry1 from './components/industries/Industry1';
import Industry2 from './components/industries/Industry2';
import Industry3 from './components/industries/Industry3';
import Industry4 from './components/industries/Industry4';
import Industry5 from './components/industries/Industry5';
import Industry from './components/industries/Industry';
import '@fortawesome/fontawesome-free/css/all.min.css';
import ScrollToTop from './components/scrool.jsx';

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Navbar />
      <Routes>
      
        <Route path="/" element={<Ghar />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/contactp" element={<Contactp />} />
        <Route path="/service" element={<Services />}/>
        <Route path="/service1" element={<Service1 />} />
        <Route path="/service2" element={<Service2 />} />
        <Route path="/service3" element={<Service3 />} />
        <Route path="/service4" element={<Service4 />} />
        <Route path="/industry1" element={<Industry1 />} />
        <Route path="/industry2" element={<Industry2 />} />
        <Route path="/industry3" element={<Industry3 />} />
        <Route path="/industry4" element={<Industry4 />} />
        <Route path="/industry5" element={<Industry5 />} />
        <Route path="/industry" element={<Industry />} />

      </Routes>
      <Footer />
    </div>
  );
}

export default App;
