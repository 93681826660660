import React from 'react';
import Accounting from '/Users/Arham/Downloads/react-project-business-app-main/react-project-business-app-main/src/assets/Accounting.jpg';
import appfolio from '/Users/Arham/Downloads/react-project-business-app-main/react-project-business-app-main/src/assets/technologies/appofolio.png';
import yardi from '/Users/Arham/Downloads/react-project-business-app-main/react-project-business-app-main/src/assets/technologies/yardi.png';
import quick from '/Users/Arham/Downloads/react-project-business-app-main/react-project-business-app-main/src/assets/technologies/quickbooks.png';
import entrata from '/Users/Arham/Downloads/react-project-business-app-main/react-project-business-app-main/src/assets/technologies/entrata.png';
import ramp from '/Users/Arham/Downloads/react-project-business-app-main/react-project-business-app-main/src/assets/technologies/ramp.png';
import rent from '/Users/Arham/Downloads/react-project-business-app-main/react-project-business-app-main/src/assets/technologies/rentmanager.png';
import podio from '/Users/Arham/Downloads/react-project-business-app-main/react-project-business-app-main/src/assets/technologies/podio.png';




function Industry1() {
  return  <div>
  <div className="title">     
    <img className='bgimg' src={Accounting} alt="" /><div className="h2wrapper"> <h2>Outsource<br />
  Accounting<br />
  Services
  </h2></div>
  </div>     
  
  <div className="main">
    <div className="mainleft">
      <img src={Accounting} alt="" width="350px"/>
    </div>
    <div className="mainright">
      <p>Outsourced accounting isn't merely crunching numbers; it's about unleashing your business's complete potential. Here's to streamlined finances, growth, and a future where your aspirations harmonize seamlessly! </p>
    </div>
  </div>
  <div className="titlee">      <h3>Expertise</h3>
  <div className="secondmain">
    <div className="secondmainleft">
      <ul>
        <li>At OGR, we boast top-notch offshore accounting professionals who possess the essential technical and communication skills to meet our clients’ needs. Our teams are proficient in popular accounting platforms</li>
        <li>Why Outsourced Accounting? Because Your Business Deserves the Best.
  
  </li>
        <li>Cost Efficiency: Certified accountants don’t come cheap. However, outsourcing significantly reduces overhead costs, freeing up funds for critical initiatives.
  </li>
        <li>Expertise Unleashed: Our professionals excel in tax planning, financial forecasting, and budgeting—like seasoned experts with calculators at their fingertips.
  </li>
        <li>Resource Optimization: Imagine the hours saved! No more wrestling with spreadsheets. Redirect that energy toward strategic decisions and enhancing customer satisfaction.
  </li>
        <li>Robust Data Security: Trust our professionals to safeguard your financial information. Their vaults are impenetrable.
          </li>
  <li>
  Scalability Flexibility: Whether it’s a busy season or expansion, outsourced services adapt seamlessly. Scale up or down effortlessly
  </li>
       
  
  
      </ul>
    </div>
    
  </div>
  </div>  
  <div className="titlee">      <h3>Technologies</h3></div>
  <div className="thirdmain">
    <img src={appfolio} alt="" />
    <img src={yardi} alt="" />
    <img src={quick} alt="" />
    <img src={entrata} alt="" />
    <img src={ramp} alt="" />
    <img src={rent} alt="" />
    <img src={podio} alt="" />
  
  
  
  </div>
  
  
  <div className="btn"></div>
      
      </div>

}

export default Industry1;
