import React from 'react';
import './Services.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode, faChartBar, faCopy, faMarker, faUserGear, faCoins, faReceipt, faHouse, faUtensils, faPhone, faUserTie, faPerson, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom


export default function Services() {

    // Icons
    const faLaptopCodeIcon = <FontAwesomeIcon icon={faLaptopCode} />
    const faChartBarIcon = <FontAwesomeIcon icon={faChartBar} />
    const faCopyIcon = <FontAwesomeIcon icon={faCopy} />
    const faMarkerIcon = <FontAwesomeIcon icon={faMarker} />
    const faUserGearIcon = <FontAwesomeIcon icon={faUserGear} />
    const faCoinsIcon = <FontAwesomeIcon icon={faCoins} />
    const acc = <FontAwesomeIcon icon={faReceipt} />
    const ghar = <FontAwesomeIcon icon={faPerson} />
    const res = <FontAwesomeIcon icon={faCheck} />



  return (
    <>
     <div id="services">
        <div className="container">
            <div className="title_headling">
                <h3>What's Services We Are Offering to Our Customers</h3>
                {/* <p>Ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.</p> */}
            </div>
            <div className="service_wrapper">
                <div className="service_box">
                    <div className="service_icon blue_icon">{acc}</div>
                    {/* <h4 className="number">01</h4> */}
                    <div className="service_content">
                        <h5> Bookkeing & Finance</h5>
                        <p style={{color:'white'}}>We have a team of amazing accounting professionals who can step in at a moment’s notice. OGR’s strategic accounting outsource solutions allow our clients to have peace of mind knowing everything in their accounting department is being handled by seasoned professionals</p>
                        <a  className="read"><Link  style={{color:'white'}} to='/service1'>Read more</Link></a>
                    </div>
                </div> 
                {/* <div className="service_box">
                    <div className="service_icon">{faChartBarIcon}</div>
                    <h4 className="number">02</h4>
                    <div className="service_content">
                        <h5>Finance</h5>
                        <p>Lorem ipsum dolor sit amet sed consectetur adipisicing elit. doloret quas saepe autem, dolor set.</p>
                        <a href="/" className="read">Read more</a>
                    </div>
                </div> */}
                <div className="service_box">
                    <div className="service_icon green_icon">{ghar}</div>
                    {/* <h4 className="number">02</h4> */}
                    <div className="service_content">
                        <h5>Administrative Support</h5>
                        <p style={{color:'white'}}>Administrative support agent can help with a company’s everyday operations. Our agents are bright, talented, and able to multi-task. They can be relied upon to take over the admin functions of your business so that you can devote your time to what you do best</p>
                        <a  className="read"><Link style={{color:'white'}} to='/service3'>Read more</Link></a>
                    </div>
                </div>
                <div className="service_box">
                    <div className="service_icon">{res}</div>
                    {/* <h4 className="number">03</h4> */}
                    <div className="service_content">
                        <h5>Customer Service</h5>
                        <p style={{color:'white'}}>Our agents can help you improve your day-to-day activities effectively by employing professionals who have customer service mindset to adapt themselves to multi-taking and resolve issues quickly</p>
                        <a  className="read"><Link style={{color:'white'}} to='/service4'>Read more</Link></a>
                    </div>
                </div>
                {/* <div className="service_box">
                    <div className="service_icon green_icon">{faUserGearIcon}</div>
                    <h4 className="number">05</h4>
                    <div className="service_content">
                        <h5>Customer Oriented</h5>
                        <p>Lorem ipsum dolor sit amet sed consectetur adipisicing elit. doloret quas saepe autem, dolor set.</p>
                        <a href="/" className="read">Read more</a>
                    </div>
                </div>
                <div className="service_box">
                    <div className="service_icon blue_icon">{faCoinsIcon}</div>
                    <h4 className="number">06</h4>
                    <div className="service_content">
                        <h5>Solution Focused</h5>
                        <p>Lorem ipsum dolor sit amet sed consectetur adipisicing elit. doloret quas saepe autem, dolor set.</p>
                        <a href="/" className="read">Read more</a>
                    </div>
                </div> */}
            </div>
        </div>
     </div>
    </>
  )
}
