import React from 'react';
import './About.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBusinessTime, faChartPie, faTruckFast, faUserClock, faHouseLaptop, faPhone, faMailBulk, faHouseMedicalCircleExclamation, faVoicemail } from '@fortawesome/free-solid-svg-icons';
import AboutImage from '../../assets/about/about_img.jpg';
// import Industry from '.src/components/Industry/Industry';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode, faChartBar, faCopy, faMarker, faUserGear, faCoins,faReceipt,faUtensils,faHouse } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

export default function About() {
    // Icons
    const faBusinessIcon = <FontAwesomeIcon icon={faBusinessTime} />
    const faChartPieIcon = <FontAwesomeIcon icon={faChartPie} />
    const faTruckFastIcon = <FontAwesomeIcon icon={faTruckFast} />
    const faUserClockIcon = <FontAwesomeIcon icon={faUserClock} />
    const faHouseLaptopIcon = <FontAwesomeIcon icon={faHouseLaptop} />
    const faPhoneIcon = <FontAwesomeIcon icon={faPhone} />
    const voice = <FontAwesomeIcon icon={faVoicemail} />


    const faLaptopCodeIcon = <FontAwesomeIcon icon={faLaptopCode} />
    const faChartBarIcon = <FontAwesomeIcon icon={faChartBar} />
    const faCopyIcon = <FontAwesomeIcon icon={faCopy} />
    const faMarkerIcon = <FontAwesomeIcon icon={faMarker} />
    const faUserGearIcon = <FontAwesomeIcon icon={faUserGear} />
    const faCoinsIcon = <FontAwesomeIcon icon={faCoins} />
    const acc = <FontAwesomeIcon icon={faReceipt} />
    const ghar = <FontAwesomeIcon icon={faHouse} />
    const res = <FontAwesomeIcon icon={faUtensils} />

  return (
    <>
    <section id="about">
        <div class="container">
            <div className="title_headling">
                <h3>Our Mission!</h3>
                <p>At <a  color='#990033 !important' className='spanoflogo'>Optimize Global Resources</a>, we specialize in providing comprehensive support services to enhance your business operations. From expert accounting and finance solutions to dedicated customer support, efficient administrative assistance, and specialized property management virtual assistance, our seasoned professionals utilize advanced tools and optimized processes to deliver exceptional results. Partner with us to streamline your workflows and achieve unparalleled efficiency and growth</p>
            </div>
            {/* <div className="about_box_wrapper">
                <div className="about_box">
                    <div className="about_icon">
                        {faBusinessIcon}
                    </div>
                    <div className="about_content">
                        <h5>Accounting</h5>
                        <p>Accounting firms need talented professionals to handle a wide range of tasks, from bookkeeping to tax preparation.</p>
                    </div>
                </div>
                <div className="about_box">
                    <div className="about_icon green_icon">
                        {faChartPieIcon}
                    </div>
                    <div className="about_content">
                        <h5>Finance</h5>
                        <p>For companies navigating complex transactions and reporting requirements, building and maintaining high-performing finance teams is critical.</p>
                    </div>
                </div>
                <div className="about_box">
                    <div className="about_icon blue_icon">
                        {faTruckFastIcon}
                    </div>
                    <div className="about_content">
                        <h5>Administration</h5>
                        <p>Every business needs a back-office support team they can rely on. Too often, companies struggle with hiring.</p>
                    </div>
                </div>
            </div> */}
            {/* <Industry /> */}
         
<div className='flexio'>
            <div className="about_box_details">
                <div className="about_col">
                    <div className="about_image">
                        <img src={AboutImage} alt="about" className="about_main"/>
                    </div>
                    
                    </div>
                </div>
                <div className="about_col more_space" style={{marginTop:'60px'}}>
                    <h3>How can we help?</h3>
                    <p>At OGR, we provide tailored solutions to meet your unique business needs. From expert accounting and customer support to efficient administrative and property management assistance, our skilled professionals use advanced technology to streamline your operations. Let us handle the details so you can focus on your core business goals.</p>
                    <div className="img_info__box">
                        <h6 className="img_info__title">GET A PRICE QUOTE TODAY!</h6>
                        <p>In an industry filled with freelance Virtual Assistants, companies partner with OGR to avoid the perpetual cycle of hiring, training, and replacing individual staffers. By mapping each process and staffing even fractional engagements with multiple professionals, we ensure continuous reliable support, all at globally competitive rates.</p>
<div className="btn_wrapper">
                        <a className="btn btntwo" href="/" style={{marginTop:'none !important'}}> <Link to='/contactp' style={{marginTop:'none !important'}}>Free Consultation</Link></a>
                    </div>
                    {/* <div className="grid_info">
                        <div className="icon">{faUserClockIcon}</div>
                        <div className="detail">
                            <h4>Data Entry</h4>
                            <h4>Research</h4>
                            <h4>Scheduling</h4>


                            
                        </div>
                    </div> */}
                    {/* <div className="grid_info">
                        <div className="icon green_icon">{faHouseLaptopIcon}</div>
                        <div className="detail">
                            <h4>Email Management</h4>
                            <h4>Calendar Management
</h4>
                            <h4>HR Process Support
</h4>

                           
                        </div>
                    </div> */}
                </div>
            </div>
            </div>
        </div>
    </section>
    </>
  )
}
